import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Auth } from "aws-amplify";
import { useNavigate } from 'react-router-dom';

const HeaderBar = ({ userEmail }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkIfAdmin();
  }, []);

  const checkIfAdmin = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups && groups.includes("Admin")) {
        setIsAdmin(true);
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload(false);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "black" }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography sx={{ flexGrow: 1 }}>Welcome, {userEmail || ""}</Typography>
        {isAdmin && (
          <Button color="inherit" onClick={() => navigate("/admin")}>
            Admin
          </Button>
        )}
        <Button color="inherit" onClick={() => navigate("/")}>
          Home
        </Button>
        <Button color="inherit" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
