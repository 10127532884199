import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { Container, Box, Typography, Divider } from "@mui/material";
import "./App.css";

// React Router for new pages
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from "HomePage";
import AdminPage from 'AdminPage'; 


import { AmplifyProvider } from "@aws-amplify/ui-react";
import CustomAuthenticator from "./components/CustomAuthenticator";


//Tables as percents /
//Leading table
//Descripters /
//Second set of tables LP +126 /

//Min Value in first table

//To do:
//

//Questions for Ashok
// Would you prefer to have the user input at the top of all the tables or in the middle as is?
//Add user input values to chart?
// Clear tables between runs?

function App() {
  return (
    <AmplifyProvider>
      <div className="App">
      <Router>
        <CustomAuthenticator>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
        </CustomAuthenticator>
        </Router>
      </div>
    </AmplifyProvider>
  );
}

export default App;
