import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Box, Typography, Divider } from "@mui/material";
import "./App.css";

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleCreateUser = async () => {
    try {
      const { user } = await Auth.signUp({
        username: "admin",
        password: "password",
        attributes: {
          email: "",
        },
      });
      setSuccess(`User ${user.getUsername()} created successfully`);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Admin Page
        </Typography>
        <Divider />
        <Typography variant="h6" component="h2" gutterBottom>
          {user ? `Welcome, ${user.getUsername()}` : "Welcome!"}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {error ? error : ""}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {success ? success : ""}
        </Typography>
        <button onClick={handleCreateUser}>Create User</button>
        <button onClick={handleSignOut}>Sign Out</button>
      </Box>
    </Container>
  );
};

export default AdminPage;
