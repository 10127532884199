import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Container, Box, Typography, Divider } from "@mui/material";
import "./App.css";

// React Router for new pages
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminPage from "./AdminPage"; // The new admin page component you will create

import { AmplifyProvider } from "@aws-amplify/ui-react";
import CustomAuthenticator from "./components/CustomAuthenticator";

//Tables as percents /
//Leading table
//Descripters /
//Second set of tables LP +126 /

//Min Value in first table

//To do:
//

//Questions for Ashok
// Would you prefer to have the user input at the top of all the tables or in the middle as is?
//Add user input values to chart?
// Clear tables between runs?

function HomePage() {
  const [userCount, setUserCount] = useState(false);
  const [BlockSize, setBlockSize] = useState(false);
  const [BlockSizeShow, setBlockSizeShow] = useState(false);
  const [RID, setRID] = useState(false);
  const [RIDShow, setRIDShow] = useState(false);
  const [MVAL, setMVAL] = useState(false);
  const [MVALShow, setMVALShow] = useState(false);
  const [DISCAASPMat, setDISCAASPMat] = useState(false);
  const [DISCBSPMat, setDISCBSPMat] = useState(false);
  const [DISCLBPMat, setDISCLBPMat] = useState(false);
  const [DISCAASPMean, setDISCAASPMean] = useState(false);
  const [DISCBSPMean, setDISCBSPMean] = useState(false);
  const [DISCLBPMean, setDISCLBPMean] = useState(false);
  const [MVALLow, setMVALLow] = useState(false);
  const [MVALHigh, setMVALHigh] = useState(false);
  const [BaseMat, setBaseMat] = useState(false);
  const [DISCAASPMatLP, setDISCAASPMatLP] = useState(false);
  const [DISCBSPMatLP, setDISCBSPMatLP] = useState(false);
  const [DISCLBPMatLP, setDISCLBPMatLP] = useState(false);
  const [DISCAASPMeanLP, setDISCAASPMeanLP] = useState(false);
  const [DISCBSPMeanLP, setDISCBSPMeanLP] = useState(false);
  const [DISCLBPMeanLP, setDISCLBPMeanLP] = useState(false);
  const [SigMean, setSigMean] = useState(false);
  const [SigQ1, setSigQ1] = useState(false);
  const [SigMedian, setSigMedian] = useState(false);
  const [SigQ3, setSigQ3] = useState(false);
  const [hlifeMean, sethlifeMean] = useState(false);
  const [hlifeQ1, sethlifeQ1] = useState(false);
  const [hlifeMedian, sethlifeMedian] = useState(false);
  const [hlifeQ3, sethlifeQ3] = useState(false);
  const [Firm, setFirm] = useState(false);
  const [FirmShow, setFirmShow] = useState(false);
  var [arraySig, setarraySig] = useState(false);
  var [arrayHlife, setarrayHlife] = useState(false);
  var [PassiveTable, setPassiveTable] = useState(false);
  var [RowName, setRowName] = useState(false);
  const [TableName, setTableName] = useState(false);
  //Bool is the inverse of Bool in app.py so bool 1 is equal to bool 0 in app.py
  const [Bool, setBool] = useState(false);
  //UIBool if there is user input == 1
  const [UIBool, setUIBool] = useState(false);
  const [isRun, setIsRun] = useState(false);
  const [UISigQ1, setUISigQ1] = useState(false);
  const [UISigMedian, setUISigMedian] = useState(false);
  const [UISigQ3, setUISigQ3] = useState(false);
  const [UIhlifeQ1, setUIhlifeQ1] = useState(false);
  const [UIhlifeMedian, setUIhlifeMedian] = useState(false);
  const [UIhlifeQ3, setUIhlifeQ3] = useState(false);
  const [DISCAASPMatUI, setDISCAASPMatUI] = useState(false);
  const [DISCBSPMatUI, setDISCBSPMatUI] = useState(false);
  const [DISCLBPMatUI, setDISCLBPMatUI] = useState(false);
  const [DISCAASPMatLPUI, setDISCAASPMatLPUI] = useState(false);
  const [DISCBSPMatLPUI, setDISCBSPMatLPUI] = useState(false);
  const [DISCLBPMatLPUI, setDISCLBPMatLPUI] = useState(false);

  // SigMean, SigQ1, SigMedian, SigQ3, hlifeMean, hlifeQ1, hlifeMedian, hlifeQ3

  //Special handling to use localhost SAM API if running locally via npm start(make run)
  const apiUrl =
    process.env.NODE_ENV !== "development"
      ? "https://" + process.env.REACT_APP_USER_API_DOMAIN + "/users"
      : process.env.REACT_APP_USER_API_URL_LOCAL_SAM;

  function MVALRange(MVALLow, MVALHigh) {
    if (Bool == 0) {
      return null;
    }
    if (Bool == 1) {
      return (
        <Typography color={"red"}>
          <br />
          Please set market value before discount to be between ${parseFloat(MVALLow).toLocaleString("en-US")} and $
          {parseFloat(MVALHigh).toLocaleString("en-US")} <br />
          These values represent the market value range for the input valuation date: {RIDShow} <br />
          If you would like to test a market value outside this range please reference the user input section below. <br />
          <br />
        </Typography>
      );
    } else {
      return null;
    }
  }

  function GenerateLeadingTable(SigMean, SigQ1, SigMedian, SigQ3, hlifeMean, hlifeQ1, hlifeMedian, hlifeQ3) {
    // Gate to catch if all params not filled
    if (!SigMean) {
      return null;
    }

    arraySig = [SigQ1, SigMedian, SigQ3, SigMean];
    arrayHlife = [hlifeQ1, hlifeMedian, hlifeQ3, hlifeMean];
    PassiveTable = [];
    RowName = ["25th Percentile", "Median", "75th Percentile"];
    for (let i = 0; i < 3; i++) {
      PassiveTable[i] = {
        name: RowName[i],
        AnnStanDev: parseFloat(arraySig[i] * 100),
        LiquidPeriod: parseFloat(arrayHlife[i]),
      };
    }
    // console.log('PassiveTable: ', PassiveTable);
    // return (PassiveTable);
    return (
      <div className="App">
        {/* <Typography >Placeholder text </Typography> */}
        <table>
          <tr>
            <th scope="corner">Peer Group Values</th>
            <th scope="row">Volatility (Annualized Standard Deviation %)</th>
            <th scope="row">Median Liquidation Period(days) with no transfer restrictions </th>
          </tr>
          {PassiveTable.map((val, key) => {
            return (
              <tr key={key}>
                <th scope="row">{val.name}</th>
                <td>{val.AnnStanDev.toFixed(2)} %</td>
                <td>{val.LiquidPeriod.toFixed(2)} </td>
              </tr>
            );
          })}
        </table>
        <br />
        {/* <br/>
        <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
        <Typography variant='h5'>Passive Growth: {parseFloat(passiveGrowthVar*100).toFixed(2)} %</Typography>
        <br/> */}
      </div>
    );
  }

  function GenerateTable(factors, TableName) {
    // Gate to catch if all params not filled
    if (!factors) {
      return null;
    }

    PassiveTable = [];
    RowName = ["Liquidation Period 25th Percentile", "Liquidation Period Median", "Liquidation Period 75th Percentile"];
    for (let i = 0; i < 3; i++) {
      PassiveTable[i] = {
        name: RowName[i],
        low: parseFloat(factors[i][0] * 100),
        mean: parseFloat(factors[i][1] * 100),
        high: parseFloat(factors[i][2] * 100),
      };
    }
    // console.log('PassiveTable: ', PassiveTable);
    // return (PassiveTable);
    return (
      <div className="App">
        {/* <Typography >Placeholder text </Typography> */}
        <table>
          <tr>
            <th scope="corner">{TableName}</th>
            <th scope="row">Volatility 25th Percentile</th>
            <th scope="row">Volatility Median</th>
            <th scope="row">Volatility 75th Percentile</th>
          </tr>
          {PassiveTable.map((val, key) => {
            return (
              <tr key={key}>
                <th scope="row">{val.name}</th>
                <td>{val.low.toFixed(3)} %</td>
                <td>{val.mean.toFixed(3)} %</td>
                <td>{val.high.toFixed(3)} %</td>
              </tr>
            );
          })}
        </table>
        <br />
        {/* <br/>
        <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
        <Typography variant='h5'>Passive Growth: {parseFloat(passiveGrowthVar*100).toFixed(2)} %</Typography>
        <br/> */}
      </div>
    );
  }

  //Hides date and mval on top of page if using user input
  function UserInputBase(UIBool) {
    //if no user input proceed as usual with display at top
    if (UIBool == 0) {
      return (
        <Box>
          <Typography variant="h5">Valuation Year and Month:</Typography>
          <form>
            <input
              type="month"
              id="start"
              name="start"
              data-testid="start-input"
              min="1993-01"
              max="2022-12"
              onChange={(RID) => setRID(RID.target.value)}
            ></input>
          </form>
          <Typography variant="h5">Market Value Before Discount:</Typography>
          <input type="number" data-testid="market-value-input" onChange={(MVAL) => setMVAL(MVAL.target.value)}></input>
          <Typography variant="h5">Block Size:</Typography>
          <Typography>As a percentage between 0 and 100</Typography>
          <input
            type="number"
            data-testid="block-size-input"
            min={0}
            max={100}
            onChange={(BlockSize) => setBlockSize(BlockSize.target.value)}
          ></input>
          <br />
          {MVALRange(MVALLow, MVALHigh)}

          <Typography variant="h5">Firm Name:</Typography>
          <input type="string" data-testid="firm-name-input" onChange={(Firm) => setFirm(Firm.target.value)}></input>
        </Box>
      );
    } else {
      return (
        <div>
          <Typography color={"red"}>
            Using values found below in the custom user input section Toggle off custom input to return to historical data:
            <button onClick={handleClick2}>Hide User Input Options</button>
          </Typography>
        </div>
      );
    }
  }

  //Displays User Input section if button is hit and hides it otherwise
  function UserInputGen(UIBool) {
    // if no user input
    if (UIBool == 0) {
      return (
        <div>
          <Typography color={"red"}>
            If you would like to try your own standard deviations and liquidation periods you can by clicking below{" "}
          </Typography>
          <button onClick={handleClick2}>Show User Input Options</button>
          <div className="pagebreak"> </div>
          <br />
        </div>
      );
    }

    //If they Want user input
    return (
      <div>
        <button onClick={handleClick2}>Hide User Input Options</button>
        <div className="pagebreak"> </div>
        <br />
        <Typography variant="h5" color={"red"}>
          The following calculations are made solely relying on the user inputs for volatility and liquidation periods.
        </Typography>
        <br />
        <Typography variant="h4">
          User Input Section <br />
        </Typography>
        <Typography variant="h5">Valuation Year and Month:</Typography>
        <form>
          <input
            type="month"
            id="start"
            data-testid="start-input"
            name="start"
            min="1993-01"
            max="2022-12"
            onChange={(RID) => setRID(RID.target.value)}
          ></input>
        </form>
        <Typography variant="h5">Market Value Before Discount:</Typography>
        <input type="number" data-testid="market-value-input" onChange={(MVAL) => setMVAL(MVAL.target.value)}></input>
        <Typography variant="h5">Block Size:</Typography>
        <Typography>As a percentage between 0 and 100</Typography>
        <input
          type="number"
          data-testid="block-size-input"
          min={0}
          max={100}
          onChange={(BlockSize) => setBlockSize(BlockSize.target.value)}
        ></input>
        <br />
        {MVALRange(MVALLow, MVALHigh)}

        <Typography variant="h5">Firm Name:</Typography>
        <input type="string" data-testid="firm-name-input" onChange={(Firm) => setFirm(Firm.target.value)}></input>
        <Typography variant="h5">
          Annualized Standard Deviation: <br />
        </Typography>
        <Typography variant="h6">Low:</Typography>
        <input type="number" step="0.01" onChange={(UISigQ1) => setUISigQ1(UISigQ1.target.value)}></input>
        <Typography variant="h6">Median:</Typography>
        <input type="number" step="0.01" onChange={(UISigMedian) => setUISigMedian(UISigMedian.target.value)}></input>
        <Typography variant="h6">High:</Typography>
        <input type="number" step="0.01" onChange={(UISigQ3) => setUISigQ3(UISigQ3.target.value)}></input>

        <Typography variant="h5">
          <br />
          Liquidation Period(days) with no transfer restrictions: <br />
        </Typography>
        <Typography variant="h6">Low:</Typography>
        <input type="number" step="0.01" min="0" onChange={(UIhlifeQ1) => setUIhlifeQ1(UIhlifeQ1.target.value)}></input>
        <Typography variant="h6">Median:</Typography>
        <input type="number" step="0.01" min="0" onChange={(UIhlifeMedian) => setUIhlifeMedian(UIhlifeMedian.target.value)}></input>
        <Typography variant="h6">High:</Typography>
        <input type="number" step="0.01" min="0" onChange={(UIhlifeQ3) => setUIhlifeQ3(UIhlifeQ3.target.value)}></input>
        <br />
        <br />
        <button onClick={handleClick}>Generate Table</button>
        <br />
        <br />
        <Typography>
          Firm Name: {FirmShow} <br />
          Valuation Date: {RIDShow} <br />
          Market Value Pre-Discount: {MVALShow} <br />
          Block Size: {BlockSizeShow} <br /> <br />
          Peer Group <br />
          Market Value: <br />
          Minimum: ${parseFloat(MVALLow).toLocaleString("en-US")} <br />
          Maximum: ${parseFloat(MVALHigh).toLocaleString("en-US")} <br />
          <br />
        </Typography>
        <div className="pagebreak"> </div>
        <Typography variant="h5">Table 3: User Input Blockage Discount: No Transfer Restrictions</Typography>
        <Typography variant="h6">Asian Average Exchange Option (AAE) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all information, public and private, about the firm. No information asymmetry.
          For example, restricted stock granted to insiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCAASPMean is {parseFloat(DISCAASPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCAASPMatUI, "Asian Average Exchange Option (AAE)")}
        <Typography variant="h6">Margrabe Exchange Option (ME) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all public information about the firm. No information asymmetry. Transaction
          between outsiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCBSPMean is {parseFloat(DISCBSPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCBSPMatUI, "Margrabe Exchange Option (ME)")}
        <Typography variant="h6">Look Back Exchange Option (LBE) </Typography>
        <Typography>
          Applicable when the buyer has access to all public information about the firm. The seller has access to all information, public
          and private, about the firm. Information asymmetry exists. For eaxmple, restricted stock placed with an outsider investor.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCLBPMean is {parseFloat(DISCLBPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCLBPMatUI, "Look Back Exchange Option (LBE)")}
        <br />
        <div className="pagebreak"> </div>
        {/*
        <Typography variant='h5'>Table 4: User Input Discount for lack of Martketability: Transfer restrictions in place</Typography>
        <Typography variant='h6'>Asian Average Exchange Option (AAE) </Typography>
        <Typography>Applicable when the buyer and seller have access to all information, public and private, about the firm. No information asymmetry. For example, restricted stock granted to insiders. </Typography>
        {/* <Typography variant='h6'>DISCAASPMean is {parseFloat(DISCAASPMeanLP*100).toFixed(5)} %</Typography> */}
        {/*
        {GenerateTable(DISCAASPMatLPUI, "Asian Average Exchange Option (AAE)")}
        <Typography variant='h6'>Margrabe Exchange Option (ME) </Typography>
        <Typography>Applicable when the buyer and seller have access to all public information about the firm. No information asymmetry. Transaction between outsiders. </Typography>
        {/* <Typography variant='h6'>DISCBSPMean is {parseFloat(DISCBSPMeanLP*100).toFixed(5)} %</Typography> */}
        {/*
        {GenerateTable(DISCBSPMatLPUI, "Margrabe Exchange Option (ME)")}
        <Typography variant='h6'>Look Back Exchange Option (LBE) </Typography>
        <Typography>Applicable when the buyer  has access to all public information about the firm. The seller has access to all information, public and private, about the firm.  Information asymmetry exists. For eaxmple, restricted stock placed with an outsider investor. </Typography>
        {/* <Typography variant='h6'>DISCLBPMean is {parseFloat(DISCLBPMeanLP*100).toFixed(5)} %</Typography> */}
        {/*
        {GenerateTable(DISCLBPMatLPUI, "Look Back Exchange Option (LBE)")}
        */}
      </div>
    );
  }

  //Function for on click to 'generate table'
  const handleClick2 = (e) => {
    //If its currently running prevent sending a second call
    if (isRun) {
      return;
    }
    setIsRun(true);

    //Flips UIBool
    if (UIBool == 0) {
      setUIBool(true);
    } else {
      setUIBool(false);
    }

    //Resets user input
    setRID(false);
    setBlockSize(false);
    setMVAL(false);
    setFirm(false);

    setDISCAASPMat(false);
    setDISCBSPMat(false);
    setDISCLBPMat(false);
    setDISCAASPMean(false);
    setDISCBSPMean(false);
    setDISCLBPMean(false);

    //Second set of tables
    setDISCAASPMatLP(false);
    setDISCBSPMatLP(false);
    setDISCLBPMatLP(false);
    setDISCAASPMeanLP(false);
    setDISCBSPMeanLP(false);
    setDISCLBPMeanLP(false);

    setMVALLow(false);
    setMVALHigh(false);

    setSigMean(false);
    setSigQ1(false);
    setSigMedian(false);
    setSigQ3(false);
    sethlifeMean(false);
    sethlifeQ1(false);
    sethlifeMedian(false);
    sethlifeQ3(false);

    // For user Input
    setDISCAASPMatUI(false);
    setDISCBSPMatUI(false);
    setDISCLBPMatUI(false);
    setDISCAASPMatLPUI(false);
    setDISCBSPMatLPUI(false);
    setDISCLBPMatLPUI(false);

    //Denotes the call is done so we can send in the next one
    setIsRun(false);
  };

  //Generates Table
  const handleClick = async (e) => {
    if (isRun) {
      return;
    }
    setIsRun(true);

    setFirmShow(Firm);
    setRIDShow(RID);
    setMVALShow(MVAL);
    setMVAL(Math.floor(MVAL));
    setBlockSizeShow(BlockSize);
    console.log("UISigQ1: ", UISigQ1);

    //Clears Last Run
    setDISCAASPMat();
    setDISCBSPMat();
    setDISCLBPMat();
    setDISCAASPMean();
    setDISCBSPMean();
    setDISCLBPMean();
    setDISCAASPMatLP();

    //Clearing from last run
    setDISCBSPMatLP();
    setDISCLBPMatLP();
    setDISCAASPMeanLP();
    setDISCBSPMeanLP();
    setDISCLBPMeanLP();

    //Clearing from last run
    setMVALLow();
    setMVALHigh();

    //Clearing from last run
    setSigMean();
    setSigQ1();
    setSigMedian();
    setSigQ3();
    sethlifeMean();
    sethlifeQ1();
    sethlifeMedian();
    sethlifeQ3();

    // For user Input Clearing from last run
    setDISCAASPMatUI();
    setDISCBSPMatUI();
    setDISCLBPMatUI();
    setDISCAASPMatLPUI();
    setDISCBSPMatLPUI();
    setDISCLBPMatLPUI();

    // This checks for user input
    if (
      UISigQ1 != false &&
      UISigMedian != false &&
      UISigQ3 != false &&
      UIhlifeQ1 != false &&
      UIhlifeMedian != false &&
      UIhlifeQ3 != false
    ) {
      // UISigQ1, UISigMedian, UISigQ3, UIhlifeQ1, UIhlifeMedian, UIhlifeQ3
      console.log("Testing UISigQ1: ", UISigQ1);

      // Get the current authenticated user session
      const session = Auth.currentSession();
      console.log("session: ", session);
      const jwtToken = session.getIdToken().getJwtToken();
      console.log("jwtToken: ", jwtToken);

      ///Call for
      fetch(
        apiUrl +
          "?BlockSize=" +
          BlockSize +
          "&RID=" +
          RID +
          "&MVAL=" +
          MVAL +
          "&UISigQ1=" +
          UISigQ1 +
          "&UISigMedian=" +
          UISigMedian +
          "&UISigQ3=" +
          UISigQ3 +
          "&UIhlifeQ1=" +
          UIhlifeQ1 +
          "&UIhlifeMedian=" +
          UIhlifeMedian +
          "&UIhlifeQ3=" +
          UIhlifeQ3,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
        .then((response) => response.json())
        //Response variables
        .then((response) => {
          console.log(response);
          setUserCount(response["User count"]);
          setDISCAASPMat(response["DISCAASPMat"]);
          setDISCBSPMat(response["DISCBSPMat"]);
          setDISCLBPMat(response["DISCLBPMat"]);
          setDISCAASPMean(response["DISCAASPMean"]);
          setDISCBSPMean(response["DISCBSPMean"]);
          setDISCLBPMean(response["DISCLBPMean"]);

          //Second set of tables
          setDISCAASPMatLP(response["DISCAASPMatLP"]);
          setDISCBSPMatLP(response["DISCBSPMatLP"]);
          setDISCLBPMatLP(response["DISCLBPMatLP"]);
          setDISCAASPMeanLP(response["DISCAASPMeanLP"]);
          setDISCBSPMeanLP(response["DISCBSPMeanLP"]);
          setDISCLBPMeanLP(response["DISCLBPMeanLP"]);

          setMVALLow(response["MVALLow"]);
          setMVALHigh(response["MVALHigh"]);

          setSigMean(response["SigMean"]);
          setSigQ1(response["SigQ1"]);
          setSigMedian(response["SigMedian"]);
          setSigQ3(response["SigQ3"]);
          sethlifeMean(response["hlifeMean"]);
          sethlifeQ1(response["hlifeQ1"]);
          sethlifeMedian(response["hlifeMedian"]);
          sethlifeQ3(response["hlifeQ3"]);
          setBool(response["Bool"]);

          // For user Input
          setDISCAASPMatUI(response["DISCAASPMatUI"]);
          setDISCBSPMatUI(response["DISCBSPMatUI"]);
          setDISCLBPMatUI(response["DISCLBPMatUI"]);
          setDISCAASPMatLPUI(response["DISCAASPMatLPUI"]);
          setDISCBSPMatLPUI(response["DISCBSPMatLPUI"]);
          setDISCLBPMatLPUI(response["DISCLBPMatLPUI"]);
        });

      //If no user input
    } else {
      // Get the current authenticated user session
      const session = await Auth.currentSession();
      console.log("session: ", session);
      const jwtToken = session.getIdToken().getJwtToken();
      console.log("jwtToken: ", jwtToken);

      fetch(apiUrl + "?BlockSize=" + BlockSize + "&RID=" + RID + "&MVAL=" + MVAL, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      })
        .then((response) => response.json())
        //Response variables
        .then((response) => {
          console.log(response);
          setUserCount(response["User count"]);
          setDISCAASPMat(response["DISCAASPMat"]);
          setDISCBSPMat(response["DISCBSPMat"]);
          setDISCLBPMat(response["DISCLBPMat"]);
          setDISCAASPMean(response["DISCAASPMean"]);
          setDISCBSPMean(response["DISCBSPMean"]);
          setDISCLBPMean(response["DISCLBPMean"]);

          //Second set of tables
          setDISCAASPMatLP(response["DISCAASPMatLP"]);
          setDISCBSPMatLP(response["DISCBSPMatLP"]);
          setDISCLBPMatLP(response["DISCLBPMatLP"]);
          setDISCAASPMeanLP(response["DISCAASPMeanLP"]);
          setDISCBSPMeanLP(response["DISCBSPMeanLP"]);
          setDISCLBPMeanLP(response["DISCLBPMeanLP"]);

          setMVALLow(response["MVALLow"]);
          setMVALHigh(response["MVALHigh"]);

          setSigMean(response["SigMean"]);
          setSigQ1(response["SigQ1"]);
          setSigMedian(response["SigMedian"]);
          setSigQ3(response["SigQ3"]);
          sethlifeMean(response["hlifeMean"]);
          sethlifeQ1(response["hlifeQ1"]);
          sethlifeMedian(response["hlifeMedian"]);
          sethlifeQ3(response["hlifeQ3"]);
          setBool(response["Bool"]);

          //Console logging for debugging
          // console.log('Length of DISCAASPMat Array: ', Object.keys(DISCAASPMat).length)
          // console.log('Length of DISCBSPMat Array: ', Object.keys(DISCBSPMat).length)
          // console.log('Length of DISCLBPMat Array: ', Object.keys(DISCLBPMat).length)
        });
    }
    setIsRun(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <br />
        <Typography variant="h2">DLOM Calculator</Typography>
        <Typography variant="h5">Dr. Ashok Abbott</Typography>
        <br />
      </header>
      <Container className="header" maxWidth="md">
        {UserInputBase(UIBool)}

        <br />
        <br />
        <button onClick={handleClick}>Generate Table</button>
        <br />
        <br />
        <Divider style={{ marginBottom: "1vh" }} />
        <Typography>
          Firm Name: {FirmShow} <br />
          Valuation Date: {RIDShow} <br />
          Market Value Pre-Discount: {MVALShow} <br />
          Block Size: {BlockSizeShow} <br /> <br />
          Peer Group <br />
          Market Value: <br />
          Minimum: ${parseFloat(MVALLow).toLocaleString("en-US")} <br />
          Maximum: ${parseFloat(MVALHigh).toLocaleString("en-US")} <br />
          <br />
        </Typography>
        {GenerateLeadingTable(SigMean, SigQ1, SigMedian, SigQ3, hlifeMean, hlifeQ1, hlifeMedian, hlifeQ3)}
        <div className="pagebreak"> </div>
        <Typography variant="h5">Discounts for Lack of Liquidity and Marketability</Typography>
        <Typography>
          Any delay in liquidation, caused by regulatory restraint (marketability), or lack of buyers at the current price (illiquidity)
          creates price risk for the holder. This price risk results in a lower price offered for stocks subject to a delayed liquidation.
          Larger blocks require longer periods to liquidate and are likely to incur significant blockage discounts.
        </Typography>
        <br />
        <Typography>
          I estimate the expected period of liquidation using the number of shares outstanding and the observed trading volume. The first
          set of blockage discounts presented below are based on this liquidation period and the observed volatility for the stocks. These
          are applicable when there are no regulatory restraints on liquidation.
        </Typography>
        <br />
        <Typography>
          Restricted stock is identical to the unrestricted stock in all respects, except for the ability to trade during the period of
          restriction. In the absence of any market frictions, this delayed liquidation, made necessary by a regulatory restraint,
          (marketability) exposes the holder to a larger price risk. Once the period of restriction ends, a liquidation period, estimated as
          above, starts. The combined discounts for trading restriction (marketability) and blockage are presented in the second set of
          tables.
        </Typography>
        <br />
        <Typography>
          Both of these sets of discounts are based on the Margrabe exchange option equation. Margrabe exchange option formula assumes that
          the buyer and seller are equally informed with all public information. In the presence of additional private information, two
          extensions of this analysis can exist. Asian Average option models are applicable when the buyer and the seller both have access
          to all information, public and private. Look back option models allow for the seller to have access to private information, in
          addition to all public information available to the buyer creating information asymmetry.
        </Typography>
        <br />
        <Typography variant="h6">
          Rule 144 holding period requirements are incorporated in the lack of marketability analysis as follows:
        </Typography>
        <Typography>After March 2008 Six months holding period ( + 126 days to liquidation Period)</Typography>
        <Typography>Before March 2008 One year holding period ( + 252 days to liquidation Period)</Typography>
        <Typography>Before February 1997 Two year holding period. (+504 days to liquidation Period)</Typography>

        <br />
        <div className="pagebreak"> </div>
        <Typography variant="h5">Table 1: Blockage Discount: No Transfer Restrictions</Typography>
        <Typography variant="h6">Asian Average Exchange Option (AAE) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all information, public and private, about the firm. No information asymmetry.
          For example, restricted stock granted to insiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCAASPMean is {parseFloat(DISCAASPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCAASPMat, "Asian Average Exchange Option (AAE)")}
        <Typography variant="h6">Margrabe Exchange Option (ME) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all public information about the firm. No information asymmetry. Transaction
          between outsiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCBSPMean is {parseFloat(DISCBSPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCBSPMat, "Margrabe Exchange Option (ME)")}
        <Typography variant="h6">Look Back Exchange Option (LBE) </Typography>
        <Typography>
          Applicable when the buyer has access to all public information about the firm. The seller has access to all information, public
          and private, about the firm. Information asymmetry exists. For eaxmple, restricted stock placed with an outsider investor.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCLBPMean is {parseFloat(DISCLBPMean*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCLBPMat, "Look Back Exchange Option (LBE)")}
        <br />
        <div className="pagebreak"> </div>
        <Typography variant="h5">Table 2: Discount for lack of Martketability: Transfer restrictions in place</Typography>
        <Typography variant="h6">Asian Average Exchange Option (AAE) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all information, public and private, about the firm. No information asymmetry.
          For example, restricted stock granted to insiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCAASPMean is {parseFloat(DISCAASPMeanLP*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCAASPMatLP, "Asian Average Exchange Option (AAE)")}
        <Typography variant="h6">Margrabe Exchange Option (ME) </Typography>
        <Typography>
          Applicable when the buyer and seller have access to all public information about the firm. No information asymmetry. Transaction
          between outsiders.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCBSPMean is {parseFloat(DISCBSPMeanLP*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCBSPMatLP, "Margrabe Exchange Option (ME)")}
        <Typography variant="h6">Look Back Exchange Option (LBE) </Typography>
        <Typography>
          Applicable when the buyer has access to all public information about the firm. The seller has access to all information, public
          and private, about the firm. Information asymmetry exists. For eaxmple, restricted stock placed with an outsider investor.{" "}
        </Typography>
        {/* <Typography variant='h6'>DISCLBPMean is {parseFloat(DISCLBPMeanLP*100).toFixed(5)} %</Typography> */}
        {GenerateTable(DISCLBPMatLP, "Look Back Exchange Option (LBE)")}

        {/* User Input section */}
        <br />
        <br />

        {/* <button onClick={handleClick2}>User Input</button> */}
        {UserInputGen(UIBool)}

        <Typography className="visitorCounter" align="center">
          Please contact "ashok.abbott@bizvalinc.com" with any questions/comments/suggestions
        </Typography>
        <Typography className="visitorCounter" align="center">
          Visitor Count: {userCount}
        </Typography>
      </Container>
    </div>
  );
}

export default HomePage;
