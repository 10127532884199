import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import "typeface-roboto";

import outputs from "./cdk-outputs.json";
const cognitoOutputs = outputs[Object.keys(outputs)[0]]; // First stack in cdk-outputs should always be cognito

// https://aws.amazon.com/blogs/mobile/import-existing-amazon-cognito-resources-with-amplify-admin-ui/
Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: cognitoOutputs.UserPoolId,
    userPoolWebClientId: cognitoOutputs.UserPoolClientId,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
